<template>
  <b-card>
    <validation-observer
      ref="formValidate"
      v-slot="{ handleSubmit }"
      tag="form">
      <b-form @submit.prevent="handleSubmit(validationForm)">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="CreditCardIcon" size="18" />
              <h4 class="mb-0 ml-75">Dados Bancários</h4>
            </div>
          </b-col>

          <b-col md="4">
            <b-form-group label-for="account-bank" label="Banco">
              <validation-provider
                #default="{ errors }"
                name="banco"
                rules="required">
                <v-select
                  id="account-bank"
                  v-model="bankData.bankId"
                  label="title"
                  :options="listaBanks"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label-for="account-agencia" label="Agencia">
              <validation-provider
                #default="{ errors }"
                name="agencia"
                rules="required">
                <b-form-input
                  type="number"
                  id="account-agencia"
                  v-model="bankData.agency"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Agencia" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="1">
            <b-form-group label-for="account-digitoa" label="Digito">
              <validation-provider
                #default="{ errors }"
                name="digito-agencia"
                rules="required">
                <b-form-input
                  id="account-digitoa"
                  v-model="bankData.agencyDigit"
                  :state="errors.length > 0 ? false : null"
                  max="9"
                  step="1"
                  type="number"
                  @change="changeAgencyDigit($event)"
                  placeholder="0" />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label-for="account-contaCorrente"
              label="Conta Corrente">
              <validation-provider
                #default="{ errors }"
                name="conta-corrente"
                rules="required">
                <b-form-input
                  id="account-contaCorrente"
                  type="number"
                  v-model="bankData.checkingAccount"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Conta Corrente" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="1">
            <b-form-group label-for="account-digitocc" label="Digito">
              <validation-provider
                #default="{ errors }"
                name="digito-conta-corrente"
                rules="required">
                <b-form-input
                  id="account-digitocc"
                  v-model="bankData.checkingAccountDigit"
                  :state="errors.length > 0 ? false : null"
                  max="9"
                  step="1"
                  type="number"
                  @change="changeAccountDigit($event)"
                  placeholder="0" />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label-for="account-tipoPix" label="Tipo Chave Pix">
              <validation-provider
                #default="{ errors }"
                name="tipo-pix"
                rules="required">
                <v-select
                  id="account-tipoPix"
                  v-model="bankData.typePix"
                  :options="listaChaves"
                  :reduce="title => title.value"
                  label="title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label-for="account-key-pix" label="Key Pix">
              <validation-provider
                #default="{ errors }"
                name="chave-pix"
                rules="required">
                <b-form-input
                  id="account-key-pix"
                  v-model="bankData.key"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Key Pix" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr class="my-2">
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1">
              Salvar Mudanças
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import toast from '@/mixins/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  
  mixins:[toast],

  data() {
    return {
      dataUser: {
        id: this.$route.params.usuario.id,
        name: '',
      },
      banco: 'NuBank',
      agencia: '0003',
      digito: '0',
      contaCorrente: '096253',
      digitocc: '0',
      tipoChave: 'Chave Aleatoria',
      chave: 'rusbe@gmail.com',
      listaBanks: [],
      listaChaves: [
        { title: 'CPF', value: 1 },
        { title: 'CNPJ', value: 2 },
        { title: 'Celular', value: 3 },
        { title: 'E-mail', value: 4 },
        { title: 'Chave Aleatória', value: 5 },
      ],
      bankData: {
        agency: null,
        agencyDigit: null,
        checkingAccount: null,
        checkingAccountDigit: null,
        typePix: null,
        key: null,
        bankId: null,
        id: null,
      },
    }
  },
  async mounted() {
    const responseDataBanks = await this.$http.get(
      '/api/services/app/Banks/GetAll',
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )

    this.listaBanks = responseDataBanks.data.result.items.map(res => ({
      value: res.bank.id,
      code: res.bank.code,
      title: res.bank.name,
      initials: res.bank.initials,
    }))

    const responseBank = await this.$http.get(
      `/api/services/app/UserBankDatas/GetUserBankDataForUserId?UserId=${this.dataUser.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    const {
      agency,
      agencyDigit,
      checkingAccount,
      checkingAccountDigit,
      typePix,
      key,
      bankId,
      id,
    } = responseBank.data.result.userBankData
    this.bankData.agency = agency
    this.bankData.agencyDigit = agencyDigit
    this.bankData.checkingAccount = checkingAccount
    this.bankData.checkingAccountDigit = checkingAccountDigit
    this.bankData.typePix = typePix
    this.bankData.key = key
    this.bankData.bankId = bankId
    this.bankData.id = id
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.formValidate.validate().then(success => {
          if (success) {
            this.alterData()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    
    changeAccountDigit($event){
      if(Number($event) > 9){
        this.bankData.checkingAccountDigit = null
        this.msgError('Digito inválido')
      }
    },
    changeAgencyDigit($event){
      if(Number($event) > 9){
        this.bankData.agencyDigit = null
        this.msgError('Digito inválido')
      }
    },
    
    async alterData() {
      try {
        const dataBank = {
          agency: this.bankData.agency,
          agencyDigit: this.bankData.agencyDigit,
          checkingAccount: this.bankData.checkingAccount,
          checkingAccountDigit: this.bankData.checkingAccountDigit,
          typePix: this.bankData.typePix,
          key: this.bankData.key,
          bankId: this.bankData.bankId,
          userId: this.dataUser.id,
        }
        const responseBank = await this.$http.post(
          '/api/services/app/UserBankDatas/CreateOrEdit',
          dataBank,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        if (responseBank) {
          this.msgSuccess('Dados registrados com sucesso')
        }
      } catch (error) {
        this.msgError('Erro ao gravar dados')
      }
    },
  },
}
</script>
