<template>
  <b-card>
    <validation-observer
      ref="personalRules"
      v-slot="{ handleSubmit }"
      tag="form">
      <b-form @submit.prevent="handleSubmit(validationForm)">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="UserIcon" size="18" />
              <h4 class="mb-0 ml-75">Dados Pessoais - {{ dataUser.name }}</h4>
            </div>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Nome Completo" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required">
                <b-form-input
                  v-model="dataUser.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="CPF ou CNPJ" label-for="account-cpf">
              <validation-provider
                #default="{ errors }"
                name="CPF ou CNPJ"
                rules="required">
                <b-form-input
                  v-model="dataUser.numberCpfCnpj"
                  v-mask="mask"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                  @input="updateMask"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="RG" label-for="account-rg">
              <validation-provider
                #default="{ errors }"
                name="rg"
                rules="required">
                <b-form-input
                  v-model="dataUser.numberRg"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Orgão Expedidor" label-for="account-orgao">
              <validation-provider
                #default="{ errors }"
                name="orgao"
                rules="required">
                <b-form-input
                  v-model="dataUser.expeditedOrgan"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Detran" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="UF" label-for="account-uf">
              <validation-provider
                #default="{ errors }"
                name="uf"
                rules="required">
                <v-select
                  id="account-uf"
                  v-model="dataUser.ufExpeditedOrgan"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="SEXO" label-for="account-sexo">
              <validation-provider
                #default="{ errors }"
                name="sexo"
                rules="required">
                <v-select
                  id="account-sexo"
                  v-model="dataUser.sexo"
                  label="title"
                  :options="listaSexo"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label-for="example-datanasc"
              label="Data de Nascimento">
              <validation-provider #default="{ errors }" name="Data de Nascimento" rules="required">
                <flat-pickr
                  v-model="dataUser.birthDate"
                  :config="config"
                  class="form-control" 
                  name="birthDate"
                  placeholder="Data de Nascimento" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Estado Civíl" label-for="account-ecivil">
              <validation-provider
                #default="{ errors }"
                name="Estado Civíl"
                rules="required">
                <v-select
                  id="account-ecivil"
                  v-model="dataUser.civilState"
                  label="title"
                  :options="estadosCivis"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label-for="nacionalidade" label="Nacionalidade">
              <validation-provider
                #default="{ errors }"
                name="Nacionalidade"
                rules="required">
                <v-select
                  id="nacionalidade"
                  v-model="dataUser.nationality"
                  label="title"
                  :options="listaNacionalidades"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input
                v-model="dataUser.emailAddress"
                name="account-e-mail"
                placeholder="Email" />
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label="Celular" label-for="account-celular">
              <validation-provider
                #default="{ errors }"
                name="celular"
                rules="required">
                <b-form-input
                  v-model="dataUser.phoneNumber"
                  v-mask="['(##) #####-####']"
                  :state="errors.length > 0 ? false : null"
                  placeholder="(99) 99999-9999" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group label="Exposição" label-for="vi-nacional">
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="dataUser.politicallyExposedPerson"
                name="checkbox-1"
                :reduce="title => title.value">
                Pessoa Políticamente Exposta?
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
        <b-row v-if="isInvestor === true">
          <b-card
            img-alt="Card image cap"
            style="max-width: 15rem;"
            border-variant="light"
            class="img-size"
            no-body>
            <b-card-body class="title-upload">
              <b-card-title class="text-center ft-size">Documento</b-card-title>
            </b-card-body>

            <b-link id="link-document" v-if="documentFile" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: documentFile.id, data: documentFile})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
            </b-link>
            <b-img id="link-document-disabled" v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />

            <b-card-body>
              <b-card-text>
                <validation-provider #default="{ errors }" name="Cartão CNPJ" rules="required">
                  <b-form-select
                    id="situationCnpj"
                    v-model="documentSituation"
                    :disabled="disableChild === 1"
                    :options="status_value" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-card-text>
            </b-card-body>
          </b-card>
          <b-card
            img-alt="Card image cap"
            style="max-width: 15rem; margin-left: 2em"
            border-variant="light"
            class="img-size"
            no-body>
            <b-card-body class="title-upload">
              <b-card-title class="text-center ft-size">Selfie Documento</b-card-title>
            </b-card-body>
            <b-link id="link-selfie" v-if="selfieDocumentFile" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: selfieDocumentFile.id, data: selfieDocumentFile})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
            </b-link>
            <b-img id="link-selfie-disabled" v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />
            <b-card-body>
              <b-card-text>
                <validation-provider #default="{ errors }" name="Cartão CNPJ" rules="required">
                  <b-form-select
                    id="situationCnpj"
                    v-model="documentSelfieSituation"
                    :disabled="disableChild === 1"
                    :options="status_value" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2 mr-1">
              Salvar Mudanças
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BCardBody,
  BCardText,
  BCardTitle,
  BLink,
  BImg,
  BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions,no-unused-vars
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import Moment from 'moment/moment'
import InvestorDocumentsService from '@/services/Investor/InvestorDocumentsService'
import BinaryObjectsService from '@/services/BinaryObjectsService'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    flatPickr,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    Moment,
    BCardBody,
    BCardText,
    BCardTitle,
    BLink,
    BImg,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: this.$route.params.usuario.id,
      isInvestor: this.$route.params.isInvestor,
      dataUser: {
        name: '',
        numberCpfCnpj: null,
        numberRg: null,
        expeditedOrgan: null,
        sexo: null,
        civilState: null,
        birthDate: null,
        nationality: null,
        politicallyExposedPerson: null,
        ufExpeditedOrgan: null,
        phoneNumber: null,
        emailAddress: null,
        userId: this.$route.params.usuario.id,
      },
      profileFile: null,
      listaNacionalidades: [
        {
          title: 'Brasileiro Nato',
          value: 1,
        },
        {
          title: 'Brasileiro Naturalizado',
          value: 2,
        },
        {
          title: 'Estrangeiro',
          value: 3,
        },
      ],
      estadosCivis: [
        {
          title: 'Solteiro',
          value: 1,
        },
        {
          title: 'Casado',
          value: 2,
        },
        {
          title: 'Separado',
          value: 3,
        },
        {
          title: 'Divorciado',
          value: 4,
        },
        {
          title: 'Viúvo',
          value: 5,
        },
      ],
      listaSexo: [
        {
          title: 'Masculino',
          value: 1,
        },
        {
          title: 'Feminino',
          value: 2,
        },
        {
          title: 'Outro',
          value: 3,
        },
      ],
      listaUf: [],
      status_value: [
        { value: 1, text: 'Aprovado' },
        { value: 2, text: 'Pendente Validação' },
        { value: 4, text: 'Rejeitado' },
      ],
      nacionalidade: 'Brasileiro Nato',
      config: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      mask: '',
      exposta: false,
      selfieDocumentFile: undefined,
      documentFile: undefined,
      documentSelfieSituation: 2,
      documentSituation: 2,
      documentsId: undefined
    }
  },
  async mounted() {
    const responseEstates = await this.$http.get(
      '/api/services/app/Estates/GetAll',
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    this.listaUf = responseEstates.data.result.items.map(res => ({
      value: res.estate.id,
      code: res.estate.code,
      title: res.estate.name,
      initials: res.estate.initials,
    }))

    const responseUserData = await this.$http.get(
      `/api/services/app/User/GetUserForEdit?Id=${this.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )

    this.dataUser.name = responseUserData.data.result.user.name
    this.dataUser.numberCpfCnpj = this.setDataCpfOrCnpj(responseUserData.data.result.user.numberCpfCnpj)
    this.dataUser.numberRg = responseUserData.data.result.user.numberRg
    this.dataUser.expeditedOrgan = responseUserData.data.result.user.expeditedOrgan
    this.dataUser.sexo = responseUserData.data.result.user.sexo
    this.dataUser.nationality = responseUserData.data.result.user.nationality
    this.dataUser.politicallyExposedPerson = responseUserData.data.result.user.politicallyExposedPerson
    this.dataUser.ufExpeditedOrgan = responseUserData.data.result.user.ufExpeditedOrgan
    this.dataUser.civilState = responseUserData.data.result.user.civilState
    this.dataUser.phoneNumber = responseUserData.data.result.user.phoneNumber
    this.dataUser.emailAddress = responseUserData.data.result.user.emailAddress
    this.dataUser.birthDate = this.frontEndDateFormat(responseUserData.data.result.user.birthDate)
    this.dataUser.id = responseUserData.data.result.user.id
    this.getInvestorDocuments()
  },
  created() {
    if (this.$route.params.usuario) {
      this.id = this.$route.params.usuario.id
    }
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.personalRules.validate().then(success => {
          if (success) {
            this.alterData()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    async alterData() {
      try {
        const responseUser = await this.$http.get(
          `/api/services/app/User/GetUserForEdit?Id=${this.id}`,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        const roleName = responseUser.data.result.roles
        const RoleNames = []
        if (roleName) {
          roleName.forEach(element => {
            RoleNames.push(element.roleName)
          })
        }

        const alterUser = {
          user: {
            id: this.id,
            name: this.dataUser.name,
            emailAddress: this.dataUser.emailAddress,
            surname: responseUser.data.result.user.surname,
            userName: responseUser.data.result.user.userName,
            typePerson: responseUser.data.result.user.typePerson,
            phoneNumber: this.dataUser.phoneNumber,
            numberCpfCnpj: this.dataUser.numberCpfCnpj.replace(/[^\d]+/g, ''),
            numberRg: this.dataUser.numberRg.replace(/[^\d]+/g, ''),
            termsOfUse: true,
            isActive: true,
            shouldChangePasswordOnNextLogin: false,
            isTwoFactorEnabled: false,
            isLockoutEnabled: false,
            sexo: this.dataUser.sexo,
            birthDate: this.dataUser.birthDate,
            answerQuizLater: false,

            expeditedOrgan: this.dataUser.expeditedOrgan,
            nationality: this.dataUser.nationality,
            politicallyExposedPerson: this.dataUser.politicallyExposedPerson,
            ufExpeditedOrgan: this.dataUser.ufExpeditedOrgan,
            civilState: this.dataUser.civilState,
          },
          AssignedRoleNames: RoleNames,
        }

        if(this.dataUser.emailAddress !== responseUser.data.result.user.emailAddress) {
          alterUser.sendActivationEmail = true
        }

        await this.$http.post(
          '/api/services/app/User/CreateOrUpdateUser',
          alterUser,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        this.updateDocumentsSituations()
        if (responseUser) {
          this.msgSuccess()
        }
      } catch (error) {
        this.msgError()
      }
    },
    formatRg(v) {
      let r = null
      if (v.length === 9) {
        r = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')
      }
      return r
    },
    frontEndDateFormat(v) {
      return Moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    updateMask(ev) {
      setTimeout(() => {
        if (ev.length > 14) {
          this.mask = '##.###.###/####-##'
        } else {
          this.mask = '###.###.###-##'
        }
      }, 3000)
    },
    setDataCpfOrCnpj(v) {
      if(v) {
        if (v.length >= 14) {
          const r = v.padStart(14, '0')
          const f = r.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  
          return f
        }
        const r = v.padStart(11, '0')
        const f = r.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        return f
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    
    async getInvestorDocuments() {      
      try {
        const { result } = (await InvestorDocumentsService.getInvestorDocuments(this.id)).data
        if(result.investorDocuments !== null) {
          this.documentFile = await this.getBinaryObjects(result.investorDocuments.documentFiletId)
          this.selfieDocumentFile = await this.getBinaryObjects(result.investorDocuments.selfieDocumentFiletId)
          this.documentSelfieSituation =  result.investorDocuments.documentSelfieSituation
          this.documentSituation = result.investorDocuments.documentSituation
          this.documentsId = result.investorDocuments.id
        }
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Erro ao obter os documentos do investidor')
      }
    },    

    async updateDocumentsSituations(){
      const document = {
        documentSelfieSituation: this.documentSelfieSituation,
        documentSituation: this.documentSituation,
        id: this.documentsId
      }
      await InvestorDocumentsService.UpdateSituation(document)
    },

    setDataFile(data) {
      localStorage.setItem('fileDataView', JSON.stringify(data))
    },
    
    async getBinaryObjects(id) {
      const r = await BinaryObjectsService.getAppBinaryObjectsForEdit(id)
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })
      // // console.log(r.data.result.appBinaryObjects)
      return r.data.result.appBinaryObjects
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
  div.img-size > img,
  div.img-size > a img {
    padding: 10px 35px
  }
  .ft-size {
    font-size: 12px;
  }
  .border-light {
      border: 1px solid #e5e5e5 !important;
  }
  .title-upload {
    border-bottom: 1px solid #e5e5e5;
    padding: 22px 0 0 0;
    margin: 0 0 15px 0;
    background-color: #e3e3e3;
  }
</style>
