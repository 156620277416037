<template>
  <b-card>
    <b-form
        @submit.prevent="alterData"
    >
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
                icon="InfoIcon"
                size="18"
            />
            <h4 class="mb-0 ml-75">
              Dados Autorização
            </h4>
          </div>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-checkbox
            id="accountSwitch2"
            v-model="activeData.isActive"
            :checked="reinfo"
            name="isActive"
            switch
            inline>
            <span>Ativar conta</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          class="mb-2">
          <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="emailAtivacao"
                class="mt-2 mr-1">
            Enviar e-mail de confirmação
          </b-button>
        </b-col>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1"
          >
            Salvar Mudanças
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      dataUser: {
        id: this.$route.params.usuario.id,
        name: '',
      },
      renot: false,
      reinfo: false,
      emailAddress: null,
      activeData: {
        userId: this.$route.params.usuario.id,
        isActive: null,
      },
    }
  },
  async created() {
    const responseUser = await this.$http.get(`/api/services/app/User/GetUserForEdit?id=${this.dataUser.id}`, {
      headers: {
        accept: 'application/json',
        'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
      },
    })
    this.emailAddress = responseUser.data.result.user.emailAddress
    this.activeData.isActive = responseUser.data.result.user.isActive
  },
  methods: {
    async alterData() {
      try {
        await this.$http.put('/api/services/app/User/ChangeActive', this.activeData, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })

        this.msgSuccess()
      } catch (error) {
        this.msgError()
      }
    },

    async emailAtivacao() {
      try {
        await this.$http.post('/api/services/app/Account/SendEmailActivationLink', {emailAddress: this.emailAddress}, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })

        this.msgSuccess('E-mail enviado com sucesso')
      } catch (error) {
        this.msgError()
      }
    },

    // success
    msgSuccess(msg = undefined) {
      this.$swal({
        icon: 'success',
        title: msg ? msg : 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
