<template>
  <b-card>
    <validation-observer
      ref="formValidate"
      v-slot="{ handleSubmit }"
      tag="form">
      <b-form @submit.prevent="handleSubmit(validationForm)">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="MapPinIcon" size="18" />
              <h4 class="mb-0 ml-75">Dados Endereço</h4>
            </div>
          </b-col>

          <b-col sm="3">
            <b-form-group label="CEP" label-for="account-cep">
              <validation-provider
                #default="{ errors }"
                name="cep"
                rules="required">
                <b-form-input
                  v-model="dataContact.zipCode"
                  v-mask="['#####-###']"
                  name="cep"
                  :state="errors.length > 0 ? false : null"
                  placeholder="99999-999"
                  @blur="buscaCep()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group label="Bairro" label-for="account-bairro">
              <validation-provider
                #default="{ errors }"
                name="bairro"
                rules="required">
                <b-form-input
                  v-model="dataContact.district"
                  name="bairro"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Bairro" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group label="Cidade" label-for="account-cidade">
              <validation-provider
                #default="{ errors }"
                name="cidade"
                rules="required">
                <b-form-input
                  v-model="dataContact.city"
                  name="cidade"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Cidade" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group label="Estado" label-for="account-estado">
              <validation-provider
                #default="{ errors }"
                name="estado"
                rules="required">
                <v-select
                  id="account-estado"
                  v-model="dataContact.estateId"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="País" label-for="countryList">
              <validation-provider
                #default="{ errors }"
                name="pais"
                rules="required">
                <v-select
                  id="countryList"
                  v-model="dataContact.country"
                  label="title"
                  :options="countryOption"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="log" label="Logradouro">
              <validation-provider
                #default="{ errors }"
                name="logradouro"
                rules="required">
                <b-form-input
                  id="log"
                  v-model="dataContact.logradouro"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Logradouro" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Número" label-for="account-numero">
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required">
                <b-form-input
                  v-model="dataContact.number"
                  name="numero"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="0000" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label="Complemento" label-for="account-comp">
              <b-form-input
                v-model="dataContact.complement"
                name="comp"
                placeholder="ap999" />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr class="my-2">
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1">
              Salvar Mudanças
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
// import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      dataUser: {
        id: this.$route.params.usuario.id,
        name: '',
      },
      countryOption: [
        {
          title: 'Brasil',
          value: 'Brasil',
        },
        {
          title: 'USA',
          value: 'USA',
        },
        {
          title: 'Canada',
          value: 'Canada',
        },
      ],
      listaUf: [],
      dataContact: {
        email: null,
        zipCode: null,
        logradouro: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        estateId: null,
        country: null,
        phoneNumber: null,
        id: null,
      },
    }
  },
  async mounted() {
    const responseEstates = await this.$http.get(
      '/api/services/app/Estates/GetAll',
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    this.listaUf = responseEstates.data.result.items.map(res => ({
      value: res.estate.id,
      code: res.estate.code,
      title: res.estate.name,
      initials: res.estate.initials,
    }))
    const responseUser = await this.$http.get(
      `/api/services/app/User/GetUserForEdit?Id=${this.dataUser.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    const { emailAddress, phoneNumber } = responseUser.data.result.user
    this.dataContact.email = emailAddress
    this.dataContact.phoneNumber = phoneNumber

    const responseAddresses = await this.$http.get(
      `/api/services/app/UserAddresses/GetUserAddressForUserId?UserId=${this.dataUser.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )

    const {
      email,
      zipCode,
      logradouro,
      number,
      complement,
      district,
      city,
      estateId,
      country,
      id,
    } = responseAddresses.data.result.userAddress

    this.dataContact.email = email
    this.dataContact.zipCode = zipCode
    this.dataContact.logradouro = logradouro
    this.dataContact.number = number
    this.dataContact.complement = complement
    this.dataContact.district = district
    this.dataContact.city = city
    this.dataContact.estateId = estateId
    this.dataContact.country = country
    this.dataContact.userId = this.dataUser.id
    this.dataContact.id = id
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.formValidate.validate().then(success => {
          if (success) {
            this.alterData()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async alterData() {
      try {
        const dataAddress = {
          email: this.dataContact.email,
          zipCode: this.dataContact.zipCode,
          logradouro: this.dataContact.logradouro,
          number: this.dataContact.number,
          complement: this.dataContact.complement,
          district: this.dataContact.district,
          city: this.dataContact.city,
          estateId: this.dataContact.estateId,
          country: this.dataContact.country,
          userId: this.dataUser.id,
          id: this.dataContact.id,
        }
        const response = await this.$http.post(
          '/api/services/app/UserAddresses/CreateOrEdit',
          dataAddress,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )

        if (response) {
          this.msgSuccess()
        }
      } catch (error) {
        this.msgError()
      }
    },
    async buscaCep() {
      try {
        await this.$http
          .get(`https://viacep.com.br/ws/${this.dataContact.zipCode}/json/`)
          .then(response => {
            this.dataContact = {
              ...this.dataContact,
              logradouro: response.data.logradouro,
              city: response.data.localidade,
              district: response.data.bairro,
            }
          })
      } catch (error) {
        this.errors = error.message
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
