<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left">
    <!-- data personal -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Dados Pessoais</span>
      </template>

      <account-setting-personal-data
        v-if="options.accountSetting.general.estates"
        :general-data="options.accountSetting.general.estates" />
    </b-tab>
    <!--/ data personal -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Segurança</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- address -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="MapPinIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Endereço</span>
      </template>

      <account-setting-address-data
        v-if="options.accountSetting.info"
        :information-data="options.accountSetting.info" />
    </b-tab>
    <!--/ address -->

    <!-- profession -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="CoffeeIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Profissão</span>
      </template>

      <account-setting-professional-data
        v-if="options.accountSetting.social"
        :social-data="options.accountSetting.social" />
    </b-tab>
    <!--/ profession -->

    <!-- bank -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="CreditCardIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Dados Bancários</span>
      </template>

      <account-setting-bank-data
        v-if="options.accountSetting.notification"
        :notification-data="options.accountSetting.notification" />
    </b-tab>
    <!--/ bank -->

    <!-- profession -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Autorização</span>
      </template>

      <account-setting-authorization
        v-if="options.accountSetting.social"
        :social-data="options.accountSetting.social" />
    </b-tab>
    <!--/ profession -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingPersonalData from './AccountSettingPersonalData.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingAddressData from './AccountSettingAddressData.vue'
import AccountSettingProfessionalData from './AccountSettingProfessionalData.vue'
import AccountSettingBankData from './AccountSettingBankData.vue'
import AccountSettingAuthorization from './AccountSettingAuthorization.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingPersonalData,
    AccountSettingPassword,
    AccountSettingAddressData,
    AccountSettingProfessionalData,
    AccountSettingBankData,
    AccountSettingAuthorization,
  },

  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      // avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
      options: {
        accountSetting: {
          general: {
            estates: [],
          },
          info: {
            bio: '',
            dob: null,
            country: 'USA',
            website: '',
            phone: 6562542568,
          },
          social: {
            socialLinks: {
              twitter: 'https://www.twitter.com',
              facebook: '',
              google: '',
              linkedIn: 'https://www.linkedin.com',
              instagram: '',
              quora: '',
            },
            connections: {
              twitter: {
                // profileImg: require('@/assets/images/avatars/11-small.png'),
                id: 'johndoe',
              },
              google: {
                // profileImg: require('@/assets/images/avatars/3-small.png'),
                id: 'luraweber',
              },
              facebook: {},
              github: {},
            },
          },
          notification: {
            commentOnArticle: true,
            AnswerOnForm: true,
            followMe: false,
            newAnnouncements: true,
            productUpdates: true,
            blogDigest: false,
          },
        },
      },
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  async mounted() {
    const responseEstates = await this.$http.get(
      '/api/services/app/Estates/GetAll',
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    this.options.accountSetting.general.estates = responseEstates.data.result.items.map(res => ({
      value: res.estate.id,
      code: res.estate.code,
      title: res.estate.name,
      initials: res.estate.initials,
    }))
  },
}
</script>
<style lang="scss">
[dir] .nav-pills .nav-link.active {
  border-color: #fff6e8;
  box-shadow: 0 4px 18px -4px rgb(255 255 255);
}
[dir] .nav-pills .nav-link.active,
[dir] .nav-pills .show > .nav-link {
  background-color: #fff6e8;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #f8af26;
}
</style>
