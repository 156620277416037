<template>
  <b-card>
    <Loader v-if="loading"></Loader>
    <b-form ref="form" @submit.prevent="submitPassword">
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="LockIcon" size="18" />
            <h4 class="mb-0 ml-75">Dados Segurança</h4>
          </div>
        </b-col>
      </b-row>
      <validation-observer ref="infoRules" tag="form">
        <b-row>
          <b-col cols="12">
            <div>Atenção administrador, o usuário poderá não receber o e-mail de alteração de senha caso o endereço de e-mail estiver desatualizado no cadastro</div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="validationFormInfo">
              Enviar reset de senha
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Loader from '@/components/Loader.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    Loader
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataUser: {
        id: this.$route.params.usuario.id,
        name: '',
      },
      reset: {
        emailAddress: this.$route.params.usuario.emailAddress,
      },
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      dataPassword: {
        currentPassword: '',
        newPassword: '',
      },
      required,
      loading: false
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.submitPassword()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async submitPassword() {
      try {
        this.loading = true
        const response = await this.$http.post(
          '/api/services/app/Account/SendPasswordResetCode',
          this.reset, {
            timeout: 30000,
          },
        )
        if (response) {
          setTimeout(() => {     
            this.msgSuccess()
            this.loading = false
          }, 500)
        }
      } catch (error) {
        this.msgError(error.response.data.error.message)      
        this.loading = false
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'E-mail enviado com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dado',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
