var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"personalRules",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validationForm)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('h4',{staticClass:"mb-0 ml-75"},[_vm._v("Dados Pessoais - "+_vm._s(_vm.dataUser.name))])],1)]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome Completo","label-for":"account-name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.dataUser.name),callback:function ($$v) {_vm.$set(_vm.dataUser, "name", $$v)},expression:"dataUser.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"CPF ou CNPJ","label-for":"account-cpf"}},[_c('validation-provider',{attrs:{"name":"CPF ou CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},on:{"input":_vm.updateMask},model:{value:(_vm.dataUser.numberCpfCnpj),callback:function ($$v) {_vm.$set(_vm.dataUser, "numberCpfCnpj", $$v)},expression:"dataUser.numberCpfCnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"RG","label-for":"account-rg"}},[_c('validation-provider',{attrs:{"name":"rg","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataUser.numberRg),callback:function ($$v) {_vm.$set(_vm.dataUser, "numberRg", $$v)},expression:"dataUser.numberRg"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Orgão Expedidor","label-for":"account-orgao"}},[_c('validation-provider',{attrs:{"name":"orgao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Detran"},model:{value:(_vm.dataUser.expeditedOrgan),callback:function ($$v) {_vm.$set(_vm.dataUser, "expeditedOrgan", $$v)},expression:"dataUser.expeditedOrgan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"UF","label-for":"account-uf"}},[_c('validation-provider',{attrs:{"name":"uf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"account-uf","label":"title","options":_vm.listaUf,"reduce":function (title) { return title.value; }},model:{value:(_vm.dataUser.ufExpeditedOrgan),callback:function ($$v) {_vm.$set(_vm.dataUser, "ufExpeditedOrgan", $$v)},expression:"dataUser.ufExpeditedOrgan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"SEXO","label-for":"account-sexo"}},[_c('validation-provider',{attrs:{"name":"sexo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"account-sexo","label":"title","options":_vm.listaSexo,"reduce":function (title) { return title.value; }},model:{value:(_vm.dataUser.sexo),callback:function ($$v) {_vm.$set(_vm.dataUser, "sexo", $$v)},expression:"dataUser.sexo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"example-datanasc","label":"Data de Nascimento"}},[_c('validation-provider',{attrs:{"name":"Data de Nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.config,"name":"birthDate","placeholder":"Data de Nascimento"},model:{value:(_vm.dataUser.birthDate),callback:function ($$v) {_vm.$set(_vm.dataUser, "birthDate", $$v)},expression:"dataUser.birthDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Estado Civíl","label-for":"account-ecivil"}},[_c('validation-provider',{attrs:{"name":"Estado Civíl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"account-ecivil","label":"title","options":_vm.estadosCivis,"reduce":function (title) { return title.value; }},model:{value:(_vm.dataUser.civilState),callback:function ($$v) {_vm.$set(_vm.dataUser, "civilState", $$v)},expression:"dataUser.civilState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"nacionalidade","label":"Nacionalidade"}},[_c('validation-provider',{attrs:{"name":"Nacionalidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"nacionalidade","label":"title","options":_vm.listaNacionalidades,"reduce":function (title) { return title.value; }},model:{value:(_vm.dataUser.nationality),callback:function ($$v) {_vm.$set(_vm.dataUser, "nationality", $$v)},expression:"dataUser.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"name":"account-e-mail","placeholder":"Email"},model:{value:(_vm.dataUser.emailAddress),callback:function ($$v) {_vm.$set(_vm.dataUser, "emailAddress", $$v)},expression:"dataUser.emailAddress"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Celular","label-for":"account-celular"}},[_c('validation-provider',{attrs:{"name":"celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####']),expression:"['(##) #####-####']"}],attrs:{"state":errors.length > 0 ? false : null,"placeholder":"(99) 99999-9999"},model:{value:(_vm.dataUser.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dataUser, "phoneNumber", $$v)},expression:"dataUser.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":"Exposição","label-for":"vi-nacional"}},[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1","reduce":function (title) { return title.value; }},model:{value:(_vm.dataUser.politicallyExposedPerson),callback:function ($$v) {_vm.$set(_vm.dataUser, "politicallyExposedPerson", $$v)},expression:"dataUser.politicallyExposedPerson"}},[_vm._v(" Pessoa Políticamente Exposta? ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr',{staticClass:"my-2"})])],1),(_vm.isInvestor === true)?_c('b-row',[_c('b-card',{staticClass:"img-size",staticStyle:{"max-width":"15rem"},attrs:{"img-alt":"Card image cap","border-variant":"light","no-body":""}},[_c('b-card-body',{staticClass:"title-upload"},[_c('b-card-title',{staticClass:"text-center ft-size"},[_vm._v("Documento")])],1),(_vm.documentFile)?_c('b-link',{staticClass:"detailing__cursor",attrs:{"id":"link-document","to":{ name: 'company-view-file' },"target":"_blank"},on:{"click":function($event){return _vm.setDataFile({id: _vm.documentFile.id, data: _vm.documentFile})}}},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/upload/upload_transparent_on.png')}})],1):_c('b-img',{attrs:{"id":"link-document-disabled","fluid":"","src":require('@/assets/images/upload/upload_transparent.png')}}),_c('b-card-body',[_c('b-card-text',[_c('validation-provider',{attrs:{"name":"Cartão CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"situationCnpj","disabled":_vm.disableChild === 1,"options":_vm.status_value},model:{value:(_vm.documentSituation),callback:function ($$v) {_vm.documentSituation=$$v},expression:"documentSituation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-card',{staticClass:"img-size",staticStyle:{"max-width":"15rem","margin-left":"2em"},attrs:{"img-alt":"Card image cap","border-variant":"light","no-body":""}},[_c('b-card-body',{staticClass:"title-upload"},[_c('b-card-title',{staticClass:"text-center ft-size"},[_vm._v("Selfie Documento")])],1),(_vm.selfieDocumentFile)?_c('b-link',{staticClass:"detailing__cursor",attrs:{"id":"link-selfie","to":{ name: 'company-view-file' },"target":"_blank"},on:{"click":function($event){return _vm.setDataFile({id: _vm.selfieDocumentFile.id, data: _vm.selfieDocumentFile})}}},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/upload/upload_transparent_on.png')}})],1):_c('b-img',{attrs:{"id":"link-selfie-disabled","fluid":"","src":require('@/assets/images/upload/upload_transparent.png')}}),_c('b-card-body',[_c('b-card-text',[_c('validation-provider',{attrs:{"name":"Cartão CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"situationCnpj","disabled":_vm.disableChild === 1,"options":_vm.status_value},model:{value:(_vm.documentSelfieSituation),callback:function ($$v) {_vm.documentSelfieSituation=$$v},expression:"documentSelfieSituation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Salvar Mudanças ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }